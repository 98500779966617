'use client';
import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import Navbar from './Navbar'; // Adjust the path as necessary

// Frequencies - "Annual" is first and selected by default
const frequencies = [
  { value: 'annual', label: 'Annual (Save 20%)' },
  { value: 'monthly', label: 'Monthly' },
];

// Tiers
const tiers = [
  {
    name: 'Starter',
    id: 'tier-starter',
    href: {
      monthly: 'https://checkout.coursecreator360.com/b/14k00Pd2G3AWdb29AI',
      annual: 'https://checkout.coursecreator360.com/b/5kA00P1jY7Rcb2U5kt',
    },
    featured: false,
    description: 'First 14-Days FREE',
    price: { monthly: '$97', annual: '$80' },
    mainFeatures: [
      'Unlimited Courses / Products',
      'Unlimited Funnels',
      'Unlimited Websites',
      'Unlimited Automations',
      'Unlimited Contacts',
      'Unlimited Customers',
      '24/7 Chat Support',
      '25+ Funnel & Website Templates',
      'Emails Are Pay As You Go',
      'SMS Are Pay As You Go',
      '$10 = 5,000 Emails/Month',
      '$10 = 840 Text Segments/Month',
    ],
  },
  {
    name: 'Premium',
    id: 'tier-premium',
    href: {
      monthly: 'https://checkout.coursecreator360.com/b/dR6cNB7Im6N85IA9AF',
      annual: 'https://checkout.coursecreator360.com/b/fZeeVJbYCb3ob2U28k',
    },
    featured: true, // This is the most popular plan
    description: 'First 30-Days FREE',
    price: { monthly: '$147', annual: '$122' },
    mainFeatures: [
      'Unlimited Courses / Products',
      'Unlimited Funnels',
      'Unlimited Websites',
      'Unlimited Automations',
      'Unlimited Contacts',
      'Unlimited Customers',
      '24/7 Chat Support',
      '1 Extra CC360 Account',
      'FREE DNS & Email Deliverability Setup',
      'Custom Branded Mobile App ($59/month)',
      'Site Skeleton Page Builder',
      '50+ Funnel & Website Templates',
      '50+ Automation & Email Templates',
      'Emails Are Pay As You Go',
      'SMS Are Pay As You Go',
      '$10 = 14,000 Emails/Month',
      '$10 = 1,000 Text Segments/Month',
    ],
  },
  {
    name: 'Elite',
    id: 'tier-elite',
    href: {
      monthly: 'https://checkout.coursecreator360.com/b/fZe9BpgeSc7s0ogaEP',
      annual: 'https://checkout.coursecreator360.com/b/fZe28XgeSb3o8UMbIS',
    },
    featured: false,
    description: 'First 30-Days FREE',
    price: { monthly: '$297', annual: '$247' },
    mainFeatures: [
      'Unlimited Courses / Products',
      'Unlimited Funnels',
      'Unlimited Websites',
      'Unlimited Automations',
      'Unlimited Contacts',
      'Unlimited Customers',
      '24/7 Chat Support',
      '5 X CC360 Accounts',
      'Bi-Weekly Coaching Calls',
      'Done-For-You Course Migration',
      'Done-For-You Funnels',
      'Unlimited Emails',
      'Priority Zoom Support',
      'SMS Are Pay As You Go',
      '$10 = 1,200 Text Segments/Month',
    ],
  },
];

// Sections and Features
const sections = [
  {
    name: 'Products & Services',
    features: [
      { name: 'Free Landing Page Website', tiers: { Starter: false, Premium: true, Elite: true } },
      { name: 'Free Course Outline', tiers: { Starter: false, Premium: true, Elite: true } },
      { name: 'Affiliate Program Access', tiers: { Starter: false, Premium: true, Elite: true } },
      { name: 'Bi-Weekly Coaching Calls', tiers: { Starter: false, Premium: false, Elite: true } },
      { name: 'Coaching Call Vault Access', tiers: { Starter: false, Premium: false, Elite: true } },
      {
        name: 'Custom Build-Outs',
        tiers: {
          Starter: false,
          Premium: false,
          Elite: '1 free per month + 20% off',
        },
      },
      {
        name: 'Course Migrations',
        tiers: { Starter: false, Premium: '$45 per funnel', Elite: 'Free' },
      },
      {
        name: 'Funnel Migrations',
        tiers: { Starter: false, Premium: '$45 per funnel', Elite: 'Free' },
      },
      {
        name: 'Custom Branded Mobile App',
        tiers: { Starter: false, Premium: '$59/month', Elite: '$59/month' },
      },
    ],
  },
  {
    name: 'Account & Usage',
    features: [
      {
        name: 'Funnel Access',
        tiers: {
          Starter: 'No access to funnel templates',
          Premium: 'Course creator funnel templates',
          Elite: 'All + Template Hub',
        },
      },
      { name: 'Number of Accounts', tiers: { Starter: '1', Premium: '2', Elite: '5' } },
      { name: 'Emails', tiers: { Starter: 'Pay per email', Premium: '14,000 Emails', Elite: 'Unlimited Emails' } },
      { name: 'SMS (per 1,000)', tiers: { Starter: '$15', Premium: '$12', Elite: '$10' } },
    ],
  },
  {
    name: 'Automation & Integrations',
    features: [
      { name: 'Automations', tiers: { Starter: true, Premium: true, Elite: true } },
      { name: 'Premium Automations', tiers: { Starter: false, Premium: true, Elite: true } },
      { name: 'Webhooks', tiers: { Starter: false, Premium: true, Elite: true } },
      { name: 'Custom API Integration Setup', tiers: { Starter: false, Premium: false, Elite: true } },
    ],
  },
  {
    name: 'Support & Services',
    features: [
      { name: 'Community Access', tiers: { Starter: true, Premium: true, Elite: true } },
      { name: 'Email Support', tiers: { Starter: true, Premium: true, Elite: true } },
      { name: '24/7 Chat Support', tiers: { Starter: true, Premium: true, Elite: true } },
      { name: 'Priority Zoom Support', tiers: { Starter: false, Premium: false, Elite: true } },
    ],
  },
  {
    name: 'Discounts',
    features: [
      { name: 'Course Creator Pro Discount', tiers: { Starter: false, Premium: '50% Off', Elite: '70% Off' } },
      { name: 'Content Bug Discount', tiers: { Starter: false, Premium: '10% Off', Elite: '20% Off' } },
      { name: 'Facebook Ads Pro', tiers: { Starter: false, Premium: false, Elite: 'Free' } },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Pricing() {
  // Set 'annual' as the default selected frequency
  const [frequency, setFrequency] = useState(frequencies[0]);

  return (
    <div className="isolate overflow-hidden">
      {/* Navbar Component */}
      <Navbar />
      <div className="flow-root bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="relative z-10">
            <h2 className="mx-auto max-w-4xl text-center text-5xl font-bold tracking-tight text-white">
              Pricing plans for growth
            </h2>
            <p className="mx-auto mt-4 max-w-2xl text-center text-lg leading-8 text-white/60">
              Choose a plan that fits your business goals.
            </p>
            <div className="mt-16 flex justify-center">
              <fieldset aria-label="Payment frequency">
                {/* Added 'by=\"value\"' to help RadioGroup compare values */}
                <RadioGroup
                  value={frequency}
                  onChange={setFrequency}
                  by="value"
                  className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white"
                >
                  {frequencies.map((option) => (
                    <RadioGroup.Option
                      key={option.value}
                      value={option}
                      className={({ checked }) =>
                        classNames('cursor-pointer rounded-full px-2.5 py-1', checked ? 'bg-red-500' : '')
                      }
                    >
                      {({ checked }) => (
                        <span className={classNames(checked ? 'text-white' : 'text-gray-300')}>
                          {option.label}
                        </span>
                      )}
                    </RadioGroup.Option>
                  ))}
                </RadioGroup>
              </fieldset>
            </div>
          </div>
          {/* Pricing Tiers */}
          <div className="relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-y-8 pt-8 pb-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <svg
              viewBox="0 0 1208 1024"
              aria-hidden="true"
              className="absolute -bottom-48 left-1/2 h-[64rem] -translate-x-1/2 translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] lg:-top-48 lg:bottom-auto lg:translate-y-0"
            >
              <ellipse cx={604} cy={512} rx={604} ry={512} fill="url(#gradient)" />
              <defs>
                <radialGradient id="gradient">
                  <stop stopColor="#F43F5E" />
                  <stop offset={1} stopColor="#E11D48" />
                </radialGradient>
              </defs>
            </svg>
            <div
              aria-hidden="true"
              className="hidden lg:absolute lg:inset-x-px lg:bottom-0 lg:top-4 lg:block lg:rounded-t-2xl lg:bg-gray-800/80 lg:ring-1 lg:ring-white/10"
            />
            {tiers.map((tier) => (
              <div
                key={tier.id}
                className={classNames(
                  tier.featured
                    ? 'z-10 bg-white shadow-xl ring-1 ring-gray-900/10 rounded-2xl'
                    : 'bg-gray-800/80 ring-1 ring-white/10 rounded-b-2xl lg:bg-transparent lg:pb-14 lg:ring-0',
                  'relative'
                )}
              >
                <div className="p-8 lg:pt-12 xl:p-10 xl:pt-14">
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.featured ? 'text-gray-900' : 'text-white',
                      'text-sm font-semibold leading-6'
                    )}
                  >
                    {tier.name}
                  </h3>
                  <div className="flex flex-col gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
                    <div className="mt-2 flex items-center gap-x-4">
                      <p
                        className={classNames(
                          tier.featured ? 'text-gray-900' : 'text-white',
                          'text-4xl font-bold tracking-tight'
                        )}
                      >
                        {tier.price[frequency.value]}
                      </p>
                      <div className="text-sm leading-5">
                        <p className={tier.featured ? 'text-gray-900' : 'text-white'}>USD</p>
                        <p
                          className={tier.featured ? 'text-gray-500' : 'text-gray-400'}
                        >{`Billed ${frequency.label}`}</p>
                      </div>
                    </div>
                    <a
                      href={tier.href[frequency.value]}
                      aria-describedby={tier.id}
                      target="_blank" // Open link in a new tab
                      rel="noopener noreferrer" // Security measure
                      className={classNames(
                        tier.featured
                          ? 'bg-red-600 shadow-sm hover:bg-red-500 focus-visible:outline-red-600'
                          : 'bg-white/10 hover:bg-white/20 focus-visible:outline-white',
                        'rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                      )}
                    >
                      Get Started
                    </a>
                  </div>
                  <div className="mt-8 flow-root sm:mt-10">
                    <ul
                      role="list"
                      className={classNames(
                        tier.featured
                          ? 'divide-gray-900/5 border-gray-900/5 text-gray-600'
                          : 'divide-white/5 border-white/5 text-white',
                        '-my-2 divide-y border-t text-sm leading-6 lg:border-t-0'
                      )}
                    >
                      {tier.mainFeatures.map((mainFeature) => (
                        <li key={mainFeature} className="flex gap-x-3 py-2">
                          <CheckIcon
                            aria-hidden="true"
                            className={classNames(
                              tier.featured ? 'text-red-600' : 'text-gray-500',
                              'h-6 w-5 flex-none'
                            )}
                          />
                          {mainFeature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Feature Comparison Section */}
      <div className="relative bg-gray-50 py-8 lg:py-14">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          {/* Feature comparison (lg+) */}
          <section aria-labelledby="comparison-heading" className="hidden lg:block">
            <h2 id="comparison-heading" className="sr-only">
              Feature comparison
            </h2>
            <div className="grid grid-cols-4 gap-x-4 border-t border-gray-900/10">
              {/* Empty first column for feature names */}
              <div aria-hidden="true" className="-mt-px"></div>
              {tiers.map((tier) => (
                <div key={tier.id} aria-hidden="true" className="-mt-px">
                  <div
                    className={classNames(
                      tier.featured ? 'border-red-600' : 'border-transparent',
                      'border-t-2 pt-10'
                    )}
                  >
                    <p
                      className={classNames(
                        tier.featured ? 'text-red-600' : 'text-gray-900',
                        'text-sm font-semibold leading-6'
                      )}
                    >
                      {tier.name}
                    </p>
                    <p className="mt-1 text-sm leading-6 text-gray-600">{tier.description}</p>
                  </div>
                </div>
              ))}
            </div>
            {/* Adjusted spacing and margins */}
            <div className="-mt-6 space-y-16">
              {sections.map((section, sectionIdx) => (
                <div key={section.name}>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">{section.name}</h3>
                  <div className="relative mt-10">
                    {/* Fake card backgrounds */}
                    <div
                      aria-hidden="true"
                      className="absolute inset-0 flex items-start space-x-4"
                    >
                      {/* Empty first column for feature names */}
                      <div className="w-1/4"></div>
                      {tiers.map((tier) => (
                        <div key={tier.id} className="w-1/4 h-full rounded-lg bg-white shadow-sm" />
                      ))}
                    </div>
                    <table className="relative w-full border-separate" style={{ borderSpacing: 0 }}>
                      <thead>
                        <tr className="text-left">
                          <th scope="col" className="w-1/4"></th>
                          {tiers.map((tier) => (
                            <th key={tier.id} scope="col" className="w-1/4"></th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {section.features.map((feature, featureIdx) => (
                          <tr key={feature.name}>
                            <th
                              scope="row"
                              className="py-3 pr-4 text-left text-sm font-normal leading-6 text-gray-900"
                            >
                              {feature.name}
                            </th>
                            {tiers.map((tier) => (
                              <td key={tier.id} className="relative px-4 py-3 text-center">
                                {typeof feature.tiers[tier.name] === 'string' ? (
                                  <span
                                    className={classNames(
                                      tier.featured ? 'font-semibold text-red-600' : 'text-gray-900',
                                      'text-sm leading-6'
                                    )}
                                  >
                                    {feature.tiers[tier.name]}
                                  </span>
                                ) : (
                                  <>
                                    {feature.tiers[tier.name] === true ? (
                                      <CheckIcon
                                        aria-hidden="true"
                                        className="mx-auto h-5 w-5 text-red-600"
                                      />
                                    ) : (
                                      <XMarkIcon
                                        aria-hidden="true"
                                        className="mx-auto h-5 w-5 text-gray-400"
                                      />
                                    )}
                                    <span className="sr-only">
                                      {feature.tiers[tier.name] === true ? 'Yes' : 'No'}
                                    </span>
                                  </>
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* Fake card borders */}
                    <div
                      aria-hidden="true"
                      className="pointer-events-none absolute inset-0 flex items-start space-x-4"
                    >
                      {/* Empty first column for feature names */}
                      <div className="w-1/4"></div>
                      {tiers.map((tier) => (
                        <div
                          key={tier.id}
                          className={classNames(
                            tier.featured ? 'ring-2 ring-red-600' : 'ring-1 ring-gray-900/10',
                            'w-1/4 h-full rounded-lg'
                          )}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
          {/* Feature comparison (up to lg) */}
          <section aria-labelledby="mobile-comparison-heading" className="lg:hidden">
            <h2 id="mobile-comparison-heading" className="sr-only">
              Feature comparison
            </h2>
            <div className="mx-auto max-w-2xl space-y-16">
              {tiers.map((tier) => (
                <div key={tier.id} className="border-t border-gray-900/10">
                  <div
                    className={classNames(
                      tier.featured ? 'border-red-600' : 'border-transparent',
                      '-mt-px w-72 border-t-2 pt-10 md:w-80'
                    )}
                  >
                    <h3
                      className={classNames(
                        tier.featured ? 'text-red-600' : 'text-gray-900',
                        'text-sm font-semibold leading-6'
                      )}
                    >
                      {tier.name}
                    </h3>
                    <p className="mt-1 text-sm leading-6 text-gray-600">{tier.description}</p>
                  </div>
                  <div className="mt-10 space-y-10">
                    {sections.map((section) => (
                      <div key={section.name}>
                        <h4 className="text-sm font-semibold leading-6 text-gray-900">{section.name}</h4>
                        <div className="relative mt-6">
                          {/* Fake card background */}
                          <div
                            aria-hidden="true"
                            className="absolute inset-y-0 right-0 hidden w-1/2 rounded-lg bg-white shadow-sm sm:block"
                          />
                          <div
                            className={classNames(
                              tier.featured ? 'ring-2 ring-red-600' : 'ring-1 ring-gray-900/10',
                              'relative rounded-lg bg-white shadow-sm sm:rounded-none sm:bg-transparent sm:shadow-none sm:ring-0'
                            )}
                          >
                            <dl className="divide-y divide-gray-200 text-sm leading-6">
                              {section.features.map((feature) => (
                                <div
                                  key={feature.name}
                                  className="flex items-center justify-between px-4 py-3 sm:grid sm:grid-cols-2 sm:px-0"
                                >
                                  <dt className="pr-4 text-gray-600">{feature.name}</dt>
                                  <dd className="flex items-center justify-end sm:justify-center sm:px-4">
                                    {typeof feature.tiers[tier.name] === 'string' ? (
                                      <span
                                        className={
                                          tier.featured ? 'font-semibold text-red-600' : 'text-gray-900'
                                        }
                                      >
                                        {feature.tiers[tier.name]}
                                      </span>
                                    ) : (
                                      <>
                                        {feature.tiers[tier.name] === true ? (
                                          <CheckIcon
                                            aria-hidden="true"
                                            className="mx-auto h-5 w-5 text-red-600"
                                          />
                                        ) : (
                                          <XMarkIcon
                                            aria-hidden="true"
                                            className="mx-auto h-5 w-5 text-gray-400"
                                          />
                                        )}
                                        <span className="sr-only">
                                          {feature.tiers[tier.name] === true ? 'Yes' : 'No'}
                                        </span>
                                      </>
                                    )}
                                  </dd>
                                </div>
                              ))}
                            </dl>
                          </div>
                          {/* Fake card border */}
                          <div
                            aria-hidden="true"
                            className={classNames(
                              tier.featured ? 'ring-2 ring-red-600' : 'ring-1 ring-gray-900/10',
                              'pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 rounded-lg sm:block'
                            )}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
